<template>
    <div class="app" style="overflow:hidden; height: 100%; width: 100%; position: relative" ref="page">

        <!-- Two Factor Authentication -->
        <div v-if="duoModal" style="position: absolute; z-index: 1000; height: 100vh; width: 100vw; backdrop-filter: blur(5px);" class="d-flex align-items-center justify-content-center">
            <div style="background-color: black; padding: 15px; border: 1px solid white" class="d-flex flex-column align-items-center rounded">
                <h3><b><i class="fa fa-lock"/>&nbsp;Two Factor Authentication</b></h3>
                <b>Enter 6 digit code</b>
                <div class="d-flex" style="padding-bottom: 0">
                    <b-form-input style="text-align: center; width: 11.95em; letter-spacing: 1em" :style="duoCode.length === 0 ? 0 : 'text-indent: 1em'"
                                  v-model="duoCode" @keyup.enter="submitDuo()" maxlength="6" @keypress="onlyNumber" autofocus/>
<!--                    <div v-for="i in 6">
                        <b-form-input v-if="i === 1" style="width: 35px" v-model="duoCode[i - 1]" maxlength="1" :id="'digit-' + i"
                                      @keyup.native="moveCursor(i, $event)" autofocus/>
                        <b-form-input v-else style="width: 35px" v-model="duoCode[i - 1]" maxlength="1" :id="'digit-' + i"
                                      @keyup.native="moveCursor(i, $event)"/>
                    </div>-->
                </div>
                <div v-if="codeAttempt < 3" style="color: red; font-size: 12px">
                    Incorrect Code: {{codeAttempt}} attempt(s) remaining
                </div>
                <div class="d-flex" style="padding-top: 10px">
                    <b-button style="margin-right: 3px;" @click="duoModal=false" class="bg-info">Cancel</b-button>
                    <b-button style="margin-left: 3px;" @click="submitDuo" class="bg-primary" :disabled="duoCode.length !== 6">Submit</b-button>
                </div>
            </div>
        </div>

        <!-- Reset Password -->
        <b-modal no-close-on-backdrop scrollable title="Password Reset" class="modal-dark"
                 v-model="passReset" >
            <div><b>Enter the following for the account you're trying to recover below.</b></div> <br>

            <b-form-input v-model="passResetInput.username" type="text" placeholder="Username"
                          class="form-control" :class="{ 'border-danger': !passResetInput.username}"
                          autofocus></b-form-input>
            <br>
            <b-form-group description="ex. +1 (999) 999-9999">
                <b-input-group>
                    <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i style="cursor:auto" class='fa fa-plus fa-md'></i>
                            </span>
                    </div>
                    <b-input-group-prepend>
                        <input placeholder="1" :class="{ 'border-danger': !passResetInput.phoneNumber || !passResetInput.countryCode}" type="text"
                               maxlength="3"
                               size="1"
                               class="form-control rounded-0" @keypress="isNumber"
                               v-model="passResetInput.countryCode">
                    </b-input-group-prepend>
                    <masked-input
                        placeholder="(999) 999-9999"
                        type="tel"
                        class="form-control"
                        :class="{ 'border-danger': !passResetInput.phoneNumber}"
                        v-model="passResetInput.phoneNumber"
                        :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                        :guide="true">
                    </masked-input>
                </b-input-group>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="passReset=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submitPassReset" :disabled="disablePasswordResetSubmit()">Submit</b-button>
            </template>
        </b-modal>

        <!-- Background Image -->
        <img src="../../../../public/img/RockfordMapWallpaper.png" style="z-index: -999; position: absolute; object-fit: cover" class="w-100 h-100"/>

        <!-- Main Page Mobile View -->
        <span v-if="mobile" style="z-index: 999">
            <div class="d-flex flex-column " style="z-index: 999">
                <div class="d-flex justify-content-center align-items-end" style="height: 42.5vh; margin: 0 1.5vw 2.5vh 1.5vw">
                    <img :src="logo" style="max-width: 100%; max-height: 320px; background-color: #040707; border: solid white 1.5vw; border-radius: 35px; padding: 15px 20px 15px 20px">
                </div>

                <div class="d-flex justify-content-center" style="height: 52.5vh; margin: 0 1.5vw 2.5vh 1.5vw">
                    <div style="background-color: #040707; border: solid white 1.5vw; width: 500px; height: 200px; padding: 20px; border-radius: 35px"
                         class=" d-flex flex-column align-items-center justify-content-center">
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input @keyup.enter="login" type="text" class="form-control" placeholder="Username"
                                          autocomplete="username email" v-model="input.username"/>
                        </b-input-group>
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input @keyup.enter="login" type="password" class="form-control"
                                          placeholder="Password" autocomplete="current-password"
                                          v-model="input.password"/>
                        </b-input-group>
                        <div class="d-flex justify-content-between w-100">
                            <div style="float:right; margin-bottom: 10px" @click="openPassReset()" class="mouse-pointer d-flex justify-content-end">
                                Forgot my password
                            </div>
                            <b-button variant="dark" class="px-4 d-flex justify-content-center" @click="login()"
                                      style="width: fit-content" :disabled="disabled">Login
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <!-- Main Page Desktop View -->
        <span v-else style="z-index: 999">
            <div class="d-flex">
                <div style="width: 2%"/>
                <div class="d-flex justify-content-center align-items-center flex-grow-1" style="height: 100vh; width: 100%">
                    <img :src="logo" style="max-width: 100%; max-height: 22vh; background-color: #040707; border: solid white 15px; border-radius: 35px; padding: 35px 50px 35px 50px; margin: 50px">
                </div>
                <div style="width: 2%"/>
                <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
                    <div style="background-color: #040707; border-left: solid white 15px; width: 470px; height: 100vh; padding: 50px"
                         class=" d-flex flex-column align-items-center justify-content-center">
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input @keyup.enter="login" type="text" class="form-control" placeholder="Username"
                                          autocomplete="username email" v-model="input.username"/>
                        </b-input-group>
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input @keyup.enter="login" type="password" class="form-control"
                                          placeholder="Password" autocomplete="current-password"
                                          v-model="input.password"/>
                        </b-input-group>
                        <div class="d-flex justify-content-between w-100">
                            <div style="float:right; margin-bottom: 10px" @click="openPassReset()" class="mouse-pointer d-flex justify-content-end">
                                Forgot my password
                            </div>
                            <b-button variant="dark" class="px-4 d-flex justify-content-center" @click="login()"
                                      style="width: fit-content" :disabled="disabled">Login
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import iss from '@/services/iss.js'
import decode from 'jwt-decode'
import MaskedInput from 'vue-text-mask';
import eleQueries from "css-element-queries";

export default {
    name: 'Login',
    components: {
        MaskedInput,
        eleQueries
    },
    data() {
        return {
            codeAttempt: 3,
            /*duoCode: new Array(6),*/
            duoCode: "",
            mobile: true,
            windowWidth: 0,
            disabled: false,
            logo: '/img/mtoplogo.png',
            input: {
                username: "",
                password: "",
            },
            duoModal: false,
            passReset: false,
            passResetInput: {
                username: "",
                phoneNumber: "",
                countryCode: ""
            }
        }
    },
    beforeCreate() {
        if (localStorage.mtopToken) {
            let militaryTime = localStorage.getItem("militaryTime");
            localStorage.clear();
            localStorage.setItem("militaryTime", militaryTime);
            location.reload();
        } else {
            localStorage.clear();
        }
    },
    mounted() {
        this.getWindowWidth();
        this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth);
            this.getWindowWidth()
        })
        new eleQueries.ResizeSensor(this.$refs["page"], () => {
        })
    },
    methods: {
        /*moveCursor(index, input) {
            if (input.key === "Backspace" || input.key === "ArrowLeft")
                this.goBackOne(index);
            else if (!isNaN(input.key) || input.keyCode === 229 || input.key === "ArrowRight")
                this.goFowardOne(index);
            else if (input.code.includes("Enter") && index === 6)
                this.submitDuo();

        },
        goFowardOne(index) {
            if (index >= 6) return;
            let ele = document.getElementById('digit-' + (index + 1));
            ele.focus();
            ele.select();
        },
        goBackOne(index) {
            if (index <= 1) return;
            let ele = document.getElementById('digit-' + (index - 1));
            ele.focus();
            ele.select();
        },*/
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        openPassReset() {
            this.passReset = true;
            this.passResetInput = {
                username: "",
                phoneNumber: "",
                countryCode: ""
            }
        },
        submitPassReset() {
            this.passReset = false;
            let temp = Object.assign({}, this.passResetInput);
            temp.phoneNumber = (this.passResetInput.phoneNumber.slice(1, 4) + this.passResetInput.phoneNumber.slice(6, 9) + this.passResetInput.phoneNumber.slice(10, 14));
            iss.startPassReset(temp.username, temp.phoneNumber).then(response => {
                this.$mToast({
                    title: 'Success',
                    text: "Password reset link will be sent to the phone number linked to your account.",
                    style: 'success'
                });
            }).catch(error => {
                console.error(error);
                this.$mToast({
                    title: 'Error',
                    text: "Error starting password reset! Check entered credentials or contact support!",
                    style: 'error'
                });
            });
        },
        isNumber(event) {
            if (!parseInt(event.key) && event.key !== '0') {
                event.preventDefault();
            }
        },
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
            let height = document.documentElement.clientHeight;
            let width = document.documentElement.clientWidth;
            this.mobile = (width / height) < 1.5;
        },
        login() {
            this.disabled = true;
            this.$mToast({
                title: 'Logging In',
                text: "Please wait a moment for two factor authentication to process",
                style: 'info'
            });
            iss.login(this.input.username, this.input.password)
                .then(() => {
                    // this.duoCode = new Array(6);
                    this.duoCode = "";
                    this.codeAttempt = 3;
                    this.duoModal = true;
                    this.disabled = false;
                }).catch(error => {
                this.disabled = false;
                this.$mToast({
                    title: 'Error',
                    text: "Check entered credentials or contact support!",
                    style: 'error'
                });
            })
        },
        disablePasswordResetSubmit() {
            return (this.passResetInput.username === "" || this.passResetInput.countryCode === "" || this.passResetInput.phoneNumber === "" || this.passResetInput.phoneNumber === "(___) ___-____")
        },
        submitDuo() {
            iss.twoFactor(this.input.username, this.input.password, this.duoCode)
                .then(response => {
                    this.duoModal = false;
                    decode
                    let token = decode(response.data.token);
                    let oldToken = decode(response.data.oldToken);
                    localStorage.setItem('tokenStatus', token.status);
                    localStorage.setItem('mtopToken', response.data.token);
                    localStorage.setItem('email', token.email);
                    localStorage.setItem('type', token.type);
                    localStorage.setItem('username', token.username);
                    localStorage.setItem('id', token._id);
                    localStorage.setItem('groups', token.groups);
                    localStorage.setItem('firstname', token.firstname);
                    localStorage.setItem('lastname', token.lastname);
                    localStorage.setItem('oldToken', response.data.oldToken);
                    localStorage.setItem('tokenExp', token.tokenExpiration);
                    localStorage.setItem('reauthToken', token.reauthToken);
                    localStorage.setItem('customFields', JSON.stringify(token.customFields));
                    localStorage.setItem('started', "false");
                    window.location = '/';
                })
                .catch(error => {
                    console.error(error);
                    if (--this.codeAttempt === 0) {
                        this.duoModal = false;
                    }
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Two factor couldn't be authenticated: " + error.response.statusText,
                        style: 'error'
                    });
                })
        },
    },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
div.mouse-pointer {
    cursor: pointer;
}

canvas {
    position: absolute;
    z-index: 1;
}

.container-fluid {
    position: relative;
    z-index: 3;
}
</style>
